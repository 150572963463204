<template>
  <div>
    <!-- faq search section -->
    <!-- 
    <section id="faq-search-filter">
      <b-card
        no-body
        class="faq-search"
        :style="{backgroundImage:`url(${require('@/assets/images/banner/banner.png')})`}"
      >
        <b-card-body class="text-center">
          <h2 class="text-primary">
            Let's answer some questions
          </h2>
          <b-card-text class="mb-2">
            or choose a category to quickly find the help you need
          </b-card-text>


            <b-input-group class="input-group-merge">
              <b-input-group-prepend is-text>
                <feather-icon icon="SearchIcon" 
                  @click="fetchData"
                  class="cursor-pointer"
                />
              </b-input-group-prepend>
              <b-form-input
                id="searchbar"
                v-model="faqSearchQuery"
                placeholder="Search faq..."
                v-on:keyup.enter="fetchData"
              />
            </b-input-group>

            <b-button       
                  variant="primary"
                  class="mt-1"
                  @click="fetchData"
                  >Search
            </b-button>

        </b-card-body>
      </b-card>
    </section>
  -->
    <!--/ faq search section -->

    <!-- frequently asked questions tabs pills -->
    <section id="faq-tabs">
      <b-tabs
        vertical
        content-class="col-12 col-md-8 col-lg-9"
        pills
        nav-wrapper-class="faq-navigation col-md-4 col-lg-3 col-12"
        nav-class="nav-left"
      >

        <!-- payment tab -->
        <b-tab
          v-for="(categoryObj, categoryName, index) in faqData"
          :key="categoryName"
          :active="!index"
          class="mb-1"
        >

          <!-- title -->
          <template #title>
            <feather-icon
              :icon="categoryObj.icon"
              size="18"
              class="mr-1"
            />
            <span class="font-weight-bold">{{ categoryObj.title }}</span>
          </template>

          <faq-question-answer :options="categoryObj" />
        </b-tab>
        <!--/ payment tab -->

      </b-tabs>
    </section>
    <!--/ frequently asked questions tabs pills -->

    <!-- contact us -->
    <section class="faq-contact">
      <b-row class="text-center">
 
        <b-col sm="6">
          <b-card
            class="shadow-none py-1 faq-contact-card"
          >
            <b-avatar
              size="42"
              rounded
              variant="light-primary"
              class="mb-2"
            >
              <feather-icon
                icon="PhoneCallIcon"
                size="18"
              />
            </b-avatar>
            <h4>+49 1525 9584656</h4>
            <span class="text-body">We are always happy to help!</span>
          </b-card>
        </b-col>
        <b-col sm="6">
          <b-card
            class="shadow-none py-1 faq-contact-card"
          >
            <b-avatar
              size="42"
              rounded
              variant="light-primary"
              class="mb-2"
            >
              <feather-icon
                icon="MailIcon"
                size="18"
              />
            </b-avatar>
            <h4>help@dentaltwin.de</h4>
            <span class="text-body">Best way to receive a quicker response!</span>
          </b-card>
        </b-col>
      </b-row>
    </section>
    <!--/ contact us -->
  </div>
</template>

<script>
import {
  BCard, BCardBody, BForm, BInputGroup, BButton,BFormInput, BCardText, BInputGroupPrepend, BTabs, BTab, BImg, BRow, BCol, BAvatar,
} from 'bootstrap-vue'
import FaqQuestionAnswer from './FaqQuestionAnswer.vue'

export default {
  components: {
    BForm,
    BCard,
    BRow,
    BCol,
    BAvatar,
    BCardBody,
    BInputGroup,
    BFormInput,
    BCardText,
    BInputGroupPrepend,
    BTabs,
    BTab,
    BButton,
    BImg,
    FaqQuestionAnswer,
  },
  data() {
    return {
      faqSearchQuery: '',
      faqData: {
        // product and services
        productServices: {
                  icon: 'SettingsIcon',
                  title: 'Product & Services',
                  subtitle: '',
                  qandA: [
                    {
                      question: 'How much can I actually save?',
                      ans:
                        'Our calculations revealed time savings and material savings of more than 50%, resulting in more than 50% cost savings.',
                    },
                    {
                      question: 'I am not happy with the results, what should I do?',
                      ans:
                        'We are working on a ticket system. For now please use help@dentaltwin.de to explain why you are not happy with the generated results.',
                    },
                    {
                      question: 'Is this product really using AI or is it just a marketing-claim?',
                      ans:
                        'Yes we are using true AI technologies in our products. Our systems are based on Deep Learning and advanced algorithms to deliver the best and most reliable quality possible.',
                    },
                    
                    {
                      question: 'How can I access your services?',
                      ans:
                        'Our software is browser-based.',
                    },
                    {
                      question: 'For which indicatons can I use the reconstructed 3D models?',
                      ans:
                        'The model has a wide range of potential uses, e.g. dental CADCAM design, implantology, smile design, and TMJ therapy.',
                    },
                    {
                      question: 'In which  software can I import the models?',
                      ans:
                        'You can import it into any CADCAM-Software, which support STL-Files as input (e.g. Exocad)',
                    },

                    {
                      question: 'Can DentalTwin also be used without a pano xray?',
                      ans:
                        'Yes, even without a pano xray from the pastDentalTwin can provide a template using AI.',
                    },
                    {
                      question: 'In which format must the xray be available?',
                      ans:
                        'The pano xray can be sent digitally as dicom or jpeg-file. Physical images on film can be sent directly to our scanning service.',
                    },
                    {
                      question: 'How exactly does the workflow look like?',
                      ans:
                        'After creating a patient case in our DentalTwinHub, the IO scan and pano xray are sent to the portal. After a few minutes the reconstructed 3D model is available for both, dental lab and clinic for the import to any CADCAM- software.  Using the Bio-Copy-function the prosthesis can be designed directly or with modifications based on the file of the original teeth.',
                    },

                  ],
                },
                // payment
                payment: {
                  icon: 'CreditCardIcon',
                  title: 'Payment & Licensing',
                  subtitle: '',
                  qandA: [
                    {
                      question: 'Does my product automatically renew?',
                      ans:
                        'No, you always have to buy new cases when you need them. There are no hidden subscriptions!',
                    },
                    {
                      question: 'Can I have a free case to test the software?',
                      ans:
                        'Yes every user gets on default 3 cases to test and check the software! If you need any further cases, please contact sales@dentaltwin.de and also mention the reason.',
                    },
                    {
                      question: 'Can I use the models for my website or flyer?',
                      ans:
                        'Yes the models are created by our AIs and are not copyrighted in anyway. So you may use them for anything you want.',
                    },
                    {
                      question: 'I want to have a bigger package, what should I do?',
                      ans:
                        'Please directly contact sales@dentaltwin.de',
                    },
                    {
                      question: 'Are there any differences in the provided packages?',
                      ans:
                        'The provided packages only differ in amount of cases and not in quality or anything else. So it does not matter if you buy 10x one case or directly the 10 cases package.',
                    },
                    {
                      question: 'Can I also pay the cases after I used them?',
                      ans:
                        'No this is not possible at the moment.',
                    },
                  ],
                },
                
              },
            }
  },

  methods: {
    fetchData() {
      console.log(this.faqSearchQuery)
      //this.$http.get('/faq/data', { params: { q: this.faqSearchQuery } }).then(res => { this.faqData = res.data })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-faq.scss';
</style>
